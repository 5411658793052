import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
// action
// import { checkAutoLogin } from './services/AuthService';

import { isAuthenticated } from './store/selectors/AuthSelectors';

import "./css/style.css";
import "./css/new.css";
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import { GetIPAddress, checkAutoLogin, CheckLoginStatus, LogOut, successMessage, errorMessage } from './Redux/Slice'

const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login/Login')), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}



function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { success, errors } = useSelector((state) => state.Astromind);

  const token = localStorage.getItem("astrologer-auth-token");

  useEffect(() => {
    dispatch(checkAutoLogin(navigate));
    dispatch(GetIPAddress());
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(CheckLoginStatus({ token }));
    }
  }, [token]);

  useEffect(() => {
    if (!(success?.data?.data?.isExpired)) {
      dispatch(successMessage(''));
    }
    if (errors?.data?.data?.isExpired) {
      Swal.fire({
        text: `${errors?.data?.message}`,
        confirmButtonColor: "#025BFD",
        confirmButtonText: "Login",
        timer: 10000
      }).then(() => {
        // dispatch(LogOut({ token, navigate }));
        const token = localStorage.removeItem('astrologer-auth-token');
        if (!token) {
          window.location.reload(false);
          return navigate('/login');
        }
        //  window.location.reload(false);
        dispatch(errorMessage(''));
      })
    }
  }, [dispatch, success, errors]);

  console.log(errors);
  
  let routeblog = (
    <Routes>
      <Route path='/login' element={<Login />} />
    </Routes>
  );

  if (props.isAuthenticated) {
    return (
      <>
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
        >
          <Index />
          {routeblog}
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
        >
          {routeblog}
        </Suspense>
      </div>
    );
  }
};


const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(),
  };
};


export default withRouter(connect(mapStateToProps)(App));

