
import { useState, useEffect } from 'react';

import { IoWarning } from "react-icons/io5";
import { RiWifiOffLine } from "react-icons/ri";

const Network = () => {

    const [isOnline, setIsOnline] = useState(true);
    const [networkType, setNetworkType] = useState(null);
    const [downlink, setDownlink] = useState(null);

    useEffect(() => {
        setIsOnline(navigator.onLine);

        const connection = (navigator).connection;

        if (connection) {
            setNetworkType(connection.effectiveType);
            setDownlink(connection.downlink);
        }

        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        const handleConnectionChange = () => {
            if (connection) {
                setNetworkType(connection.effectiveType);
                setDownlink(connection.downlink);
            }
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        if (connection) {
            connection.addEventListener('change', handleConnectionChange);
        }

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);

            if (connection) {
                connection.removeEventListener('change', handleConnectionChange);
            }
        };
    }, []);

    const isLowNetwork = () => {
        if (!isOnline) return true;
        if (networkType && (networkType === 'slow-2g' || networkType === '2g')) return true;
        if (downlink !== null && downlink < 0.5) return true;
        return false;
    };

    return (
        <>
            <div >
                {!isOnline && <div className='offline d-flex gap-2'>
                    <div className='d-flex align-items-center'>
                        <RiWifiOffLine className='icon' />
                    </div>
                    <div>
                        You are offline! Please check your internet connection.
                    </div>
                </div>}

                {isLowNetwork() && isOnline && (
                    <div className='low-network d-flex gap-2' >
                        <div className='d-flex align-items-center'>
                            <IoWarning className='icon' />
                        </div>
                        <div>
                            Your network speed is slow. This may affect performance. Switching to a faster connection.
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default Network;