import { Row, Col } from 'react-bootstrap';

import MainPagetitle from '../../../layouts/MainPagetitle';


function TrainingVideos() {
    return (
        <>
            <MainPagetitle pageTitle="Training Videos" parentTitle="Home" />
            <Row className='margin-3 training-videos'>
                <Col>
                    <h2>Training Videos</h2>
                </Col>
                <Col lg={12} className='d-flex flex-wrap justify-content-between gap-2'>
                    <Col lg={4} className='training-videos-card'>
                        <video src="https://astromind-prod.s3.ap-south-1.amazonaws.com/video6201863308622960141.mp4"
                            controls
                        >
                        </video>
                        <h5 >How to login & attend chat/call  as astrologer with customer?</h5>

                    </Col>
                    <Col lg={4} className='training-videos-card'>
                        <video src="https://astromind-prod.s3.ap-south-1.amazonaws.com/video6206038673964666562.mp4"
                            controls
                        >
                        </video>
                        <h5 >How to update Bank details in your profile?</h5>

                    </Col>
                    <Col lg={4} className='training-videos-card'>
                        <video src="https://astromind-prod.s3.ap-south-1.amazonaws.com/video6206038673964666559.mp4"
                            controls
                        >
                        </video>
                        <h5 >How to enable Offer in your profile for customers?</h5>

                    </Col>
                </Col>
            </Row>
        </>
    )
}

export default TrainingVideos;