import React, { useState, useEffect, useRef } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Col } from 'react-bootstrap';
import useInterval from 'use-interval';
import Swal from 'sweetalert2';

import Modal from 'react-bootstrap/Modal';

import { IoSend } from "react-icons/io5";
import { TiAttachment } from "react-icons/ti";
import { FaFileDownload } from "react-icons/fa";
import { MdDownload } from "react-icons/md";

import Chart from '../../../images/dashboard/horoscope-chart.png';

import GetRemainingTime from "./GetRemainingTime";
import FileUpload from "./FileUpload";
import ChatChart from "./ChatChart";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetChatSession, GetChatExtended, UpdateChatEnd, successMessage, errorMessage, GetWaitingList, GetInprogressChatStatus } from '../../../Redux/Slice';

// import io from "socket.io-client";

function ChatConnect({ socket, showConnect, waitingList, chatClose }) {
    const dispatch = useDispatch();
    const { success, errors, ChatHistory, ChatConnectStatus, ChatExtendedStatus, WaitingList, InprogressChatStatus } = useSelector((state) => state.Astromind);

    const textareaRef = useRef(null);
    const chatfeedRef = useRef(null);

    const astrologerName = localStorage.getItem("roomName");
    const astrologerToken = localStorage.getItem("astrologer-auth-token");
    const orderId = localStorage.getItem("chatOrderId");
    const chatSessionId = localStorage.getItem("messageSessionId");
    const userId = localStorage.getItem("userId");
    const duration = localStorage.getItem("chatDuration");
    const ElapsedTime = localStorage.getItem("ElapsedTime");

    const [fileStatus, setFileStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [receivedMessage, setReceivedMessage] = useState([]);
    const [leftMessage, setLeftMessage] = useState("");
    const [chatEnd, setChatEnd] = useState(false);

    const [viewChart, setViewChart] = useState(false);
    const [trackTypingMessage, setTrackMessage] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [showConnectStatus, setShowConnectStatus] = useState(false);

    const [chatDuration, setChatDuration] = useState({ Hour: '', Minutes: '', Seconds: '' });
    const [time, setTime] = useState({ Hour: '', Minutes: '', Seconds: '', ElapsedTime: '' });

    const isWaiting = WaitingList?.data?.message == "No Waiting Data" ? true : false;

    useEffect(() => {
        scrollToBottom();
    }, [receivedMessage]);

    const scrollToBottom = () => {
        if (chatfeedRef.current) {
            chatfeedRef.current.scrollTop = chatfeedRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        if (orderId) {
            dispatch(GetInprogressChatStatus({ token: astrologerToken, orderId }));
        }
    }, []);

    useInterval(() => {
        if (InprogressChatStatus?.data?.data?.status === 'in-progress' || InprogressChatStatus?.data?.data?.status === 'pending') {
            dispatch(GetInprogressChatStatus({ token: astrologerToken, orderId }));
        }
    }, 1000);

    useInterval(() => {
        if (InprogressChatStatus?.data?.data?.status === 'pending') {
            setShowConnectStatus(true);
        }
    }, 60000);

    useEffect(() => {
        if (InprogressChatStatus?.data?.data?.status === 'in-progress') {
            let remainingSeconds = InprogressChatStatus?.data?.data?.remainingTime;
            let Hour = Math.floor(remainingSeconds / 3600);
            let remainingSecondsAfterHours = remainingSeconds % 3600;
            let Minutes = Math.floor(remainingSecondsAfterHours / 60);
            let Seconds = remainingSecondsAfterHours % 60;
            let StartTime = new Date(InprogressChatStatus?.data?.data?.startTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            setTime({
                Hour: Hour > 0 ? Hour : 0,
                Minutes: Minutes,
                Seconds: Seconds,
                StartTime: StartTime,
                EndTime: ''
            })
            setShowConnectStatus(false);
        }
        else if (InprogressChatStatus?.data?.data?.status === 'completed') {
            let completedSeconds = InprogressChatStatus?.data?.data?.completedTime;
            let Hour = Math.floor(completedSeconds / 3600);
            let completedSecondsAfterHours = completedSeconds % 3600;
            let Minutes = Math.floor(completedSecondsAfterHours / 60);
            let Seconds = completedSecondsAfterHours % 60;
            let StartTime = new Date(InprogressChatStatus?.data?.data?.startTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            let EndTime = new Date(InprogressChatStatus?.data?.data?.endTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

            setTime({
                Hour: Hour > 0 ? Hour : 0,
                Minutes: Minutes,
                Seconds: Seconds,
                StartTime: StartTime,
                EndTime: EndTime
            })
            setChatEnd(true);
        }
    }, [InprogressChatStatus])

    const handleChangeMessage = (e) => {
        let input = e.target.value;
        const isNumber = /^\d+$/.test(input);
        const hasDigit = /\d/.test(input);

        // if (isNumber || hasDigit) {
        //     input = maskPhoneNumber(input);
        // }
        setMessage(input);
        e.target.style.height = '35px';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    const maskPhoneNumber = (input) => {
        const maskedInput = input.replace(/\d/g, '*');
        return maskedInput;
    };

    const fileClose = (value) => {
        setFileStatus(value);
    }

    const chartClose = (value) => {
        setViewChart(value);
    }

    const AstrologerEndChat = () => {
        Swal.fire({
            text: "Are you sure wants to end the chat",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#CA0505",
            confirmButtonText: "Confirm"
        }).then((result) => {
            if (result?.isConfirmed) {
                EndChat();
            }
        });
    }
    const EndChat = () => {
        let chatUpdate = {
            orderId: orderId,
            isExtended: 0,
            extendedDuration: 0,
            isendedbyuser: 0,
            isendedbyastrologer: 1
        }
        dispatch(UpdateChatEnd({ token: astrologerToken, chatUpdate }));
        setShowConnectStatus(false);
    }

    const handleClose = () => {
        localStorage.removeItem("roomName");
        localStorage.removeItem("userId");
        localStorage.removeItem("messageSessionId");
        localStorage.removeItem("chatOrderId");
        chatClose(false);
        window.location.reload(false);
    }

    const handleNotTyping = () => {
        if (trackTypingMessage?.length === message?.length || message?.length === 0) {
            socket.emit('not typing', { room: astrologerName });
        }
        else {
            setTrackMessage(message);
            socket.emit('typing', { astrologerName });
        }
    };

    useInterval(() => {
        handleNotTyping();
    }, 2000)

    useEffect(() => {
        const handleTyping = () => {
            setIsTyping(true);
        }
        socket.on('typing', handleTyping);
        return () => {
            socket.off("typing", handleTyping);
        };
    });

    useEffect(() => {
        const handleNotTyping = () => {
            setIsTyping(false);
        }
        socket.on('not typing', handleNotTyping);
        return () => {
            socket.off("not typing", handleNotTyping);
        };
    });

    useEffect(() => {
        if (success) {
            if (success?.data?.file) {
                socket.emit("sendMessage",
                    {
                        room: astrologerName,
                        message: success?.data?.file,
                        role: 'astrologer',
                        token: astrologerToken,
                        to: userId,
                        messageSessionId: chatSessionId
                    });
                let message = success?.data?.file;
                let MessageTime = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
                setReceivedMessage(prevMessages => [...prevMessages, { message, role: 'astrologer', time: MessageTime }]);
                setFileStatus(false);
                dispatch(successMessage(''));
                console.log("file call");
            }
            else if (success?.data?.data?.isCompleted) {
                Swal.fire({
                    title: success?.data?.message,
                    icon: "success",
                    iconColor: "#36AA00",
                    confirmButtonColor: "#36AA00",
                    confirmButtonText: "OKay",
                    timer: 5000
                }).then(() => {
                    dispatch(successMessage(' '));
                    let role = 'astrologer';
                    socket.emit('leaveRoom', astrologerName, role);
                    setChatEnd(true);
                })
            }

        }

        else if (errors?.status === 400) {
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(errorMessage({ errors: '' }));
                setChatEnd(true);
            })
        }

        // if (ChatConnectStatus) {
        //     Swal.fire({
        //         title: ChatConnectStatus?.data?.message,
        //         icon: "success",
        //         iconColor: "#36AA00",
        //         confirmButtonColor: "#36AA00",
        //         confirmButtonText: "OKay",
        //         timer: 5000
        //     }).then(() => {
        //         dispatch(ChatConnectStatus(" "));
        //         let role = 'astrologer';
        //         socket.emit('leaveRoom', astrologerName, role);
        //     })
        // }
        if (ElapsedTime) {
            let Hour = Math.floor(ElapsedTime / 3600);
            let remainingSecondsAfterHours = ElapsedTime % 3600;
            let Minutes = Math.floor(remainingSecondsAfterHours / 60);
            let Seconds = remainingSecondsAfterHours % 60;
            setChatDuration({
                Hour: Hour,
                Minutes: Minutes,
                Seconds: Seconds,
            });
        }

        if (isWaiting) {
            setTimeout(() => {
                handleClose();
            }, 10000)
        }
    }, [dispatch, success, ChatConnectStatus, ElapsedTime, astrologerName, astrologerToken, chatSessionId, userId, WaitingList, isWaiting])

    useEffect(() => {
        if (ChatHistory) {
            for (let i = 0; i < ChatHistory?.data?.data?.length; i++) {
                let MessageTime = new Date(ChatHistory?.data?.data[i]?.createdAt).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
                let obj = {
                    message: ChatHistory?.data?.data[i]?.message,
                    role: ChatHistory?.data?.data[i]?.role,
                    time: MessageTime,
                };
                setReceivedMessage(prevMessages => [...prevMessages, obj]);
            }
        }
    }, [ChatHistory])

    const sendMessage = () => {
        if (message != '') {
            let string = message;
            const regex = /\b\d{10}\b/;
            const match = string.match(regex);
            console.log(string);

            if (match) {
                string = string.replace(/\d/g, '*');
            }
            console.log(astrologerName);

            socket.emit("sendMessage",
                {
                    room: astrologerName,
                    message: string,
                    role: 'astrologer',
                    token: astrologerToken,
                    to: userId,
                    messageSessionId: chatSessionId
                });
            let MessageTime = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            setReceivedMessage(prevMessages => [...prevMessages, { message: string, role: 'astrologer', time: MessageTime }]);
        }

        setMessage("");
    };

    useEffect(() => {
        const handleReceiveMessage = (message, role) => {
            console.log("test");

            let MessageTime = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            let obj = {
                message: message,
                role: role,
                time: MessageTime,
            };
            setReceivedMessage(prevMessages => [...prevMessages, obj]);
        };

        socket.on("receiveMessage", handleReceiveMessage);
        return () => {
            socket.off("receiveMessage", handleReceiveMessage);
        };
    });

    useEffect(() => {
        const handleLeft = (role) => {
            console.log("left chat", role);
            let MessageTime = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            let obj = {
                message: `${role} left the chat`,
                role: role,
                time: MessageTime,
                isleft: true,
            };
            // localStorage.setItem("ElapsedTime", remainTime?.elapsedDuration);
            setLeftMessage(obj);
            setChatEnd(true);
        };

        socket.on("onleft", handleLeft);
        return () => {
            socket.off("onleft", handleLeft);
        };
    }, []);

    useEffect(() => {
        const handleJoin = (role) => {
            console.log("join chat", role?.role);
            let MessageTime = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            let obj = {
                message: `Your Chat has been connected`,
                role: role?.role,
                time: MessageTime,
            };
            console.log(obj);
            setReceivedMessage(prevMessages => [...prevMessages, obj]);
            // setChatEnd(true);
        };
        socket.on("onjoin", handleJoin);
        return () => {
            socket.off("onjoin", handleJoin);
        };
    }, []);

    // const EndChat = () => {
    //     setChatEnd(true);
    //     socket.emit('leaveRoom', astrologerName, "astrologer");
    //  }

    const handleKeyPress = (e) => {
        let newLine = '\n';
        if (e.ctrlKey && e.key === 'Enter') {
            setMessage((prevValue) => prevValue + '\n');
            if (textareaRef.current) {
                textareaRef.current.style.height = 'auto';
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
        }
        if (e.key === 'Enter' && !(message.includes(newLine)) && message != " ") {
            e.preventDefault();
            sendMessage();
            textareaRef.current.style.height = '35px';
        }
    };

    useEffect(() => {
        socket.emit("join", { room: astrologerName, role: 'astrologer' });
    }, []);

    useEffect(() => {
        let messageSessionId = localStorage.getItem('messageSessionId');
        dispatch(GetChatSession({ token: astrologerToken, messageSessionId }));
    }, []);

    // useInterval(() => {
    //     let messageSessionId = localStorage.getItem('messageSessionId');
    //     dispatch(GetChatSession({ token: astrologerToken, messageSessionId }));
    // }, 2000)



    // useEffect(() => {
    //     // let Hour = Math.floor(durationInSeconds / 3600);
    //     // let remainingSecondsAfterHours = durationInSeconds % 3600;
    //     // let Minutes = Math.floor(remainingSecondsAfterHours / 60);
    //     // let Seconds = remainingSecondsAfterHours % 60;
    //     const interval = setInterval(() => {
    //         let Hour = remainTime?.hour;
    //         let Minutes = remainTime?.minutes;
    //         let Seconds = remainTime?.seconds;
    //         let ElapsedTime = remainTime?.elapsedDuration;

    //         if (Minutes > 0) {
    //             setTime({
    //                 Hour,
    //                 Minutes,
    //                 Seconds
    //             });
    //             localStorage.setItem("ElapsedTime", ElapsedTime);
    //         }

    //         if (Minutes <= 0 && Seconds <= 0) {
    //             setTime({
    //                 Hour: 0,
    //                 Minutes: 0,
    //                 Seconds: 0,
    //                 ElapsedTime: ElapsedTime
    //             });
    //             // localStorage.setItem("ElapsedTime", ElapsedTime);
    //             setChatEnd(true);
    //             clearInterval(interval);
    //         }

    //         if (Minutes == 1) {
    //             if (WaitingList?.data?.data?.waiting?.duration) {
    //                 localStorage.setItem("chatDuration", WaitingList?.data?.data?.waiting?.duration);
    //                 setDurationInSeconds(duration);
    //             }
    //             localStorage.setItem("ElapsedTime", ElapsedTime);
    //         }
    //     }, 1000); // Call this every 1 second

    //     return () => clearInterval(interval);
    // }, [durationInSeconds, remainTime]);


    const formatTime = (value) => {
        return value < 10 ? `0${value}` : value;
    };

    return (
        <>
            {
                fileStatus ? <FileUpload fileShow={fileStatus} fileClose={fileClose} /> : null
            }
            {
                viewChart ? <ChatChart show={viewChart} closeChart={chartClose} waitingList={waitingList} /> : null
            }

            {
                showConnectStatus ?
                    <Modal
                        size="sm"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={showConnectStatus}
                        className='notconnect-alert'
                    >
                        <Modal.Body>
                            <p>User is not connected to the chat</p>
                            <button onClick={() => AstrologerEndChat()}>End Chat</button>
                        </Modal.Body>
                    </Modal>
                    : null
            }

            <Offcanvas
                className="call-canvas chat-canvas"
                show={showConnect}
                placement="end"
                name="end"
                backdrop={false}
                scroll={true}
            >
                <Offcanvas.Header>
                    <div>
                        <h4 className="text-white user-name">{waitingList?.waiting?.subuser?.name}</h4>
                        <p className="text-left text-white mb-0">{InprogressChatStatus?.data?.data?.status}</p>
                        {
                            InprogressChatStatus?.data?.data?.status === 'completed' ?
                                <p className="balance-time">Completed Duration : <span className="time-count">{formatTime(time?.Minutes)} Min {formatTime(time?.Seconds)} Sec</span> </p>
                                :
                                <p className="balance-time">Balance Time : <span className="time-count text-white">
                                    {formatTime(time?.Hour)}:{formatTime(time?.Minutes)}:{formatTime(time?.Seconds)}
                                </span> </p>
                        }
                        {
                            chatEnd ?
                                null
                                : <p className={isTyping ? "typing-status" : "typing-status hide"}> Typing.....</p>
                        }

                    </div>
                    <div className="d-flex flex-column align-items-end">
                        {
                            chatEnd ?
                                <button onClick={() => handleClose()}>Close</button>
                                : <button onClick={() => AstrologerEndChat()}>End Chat</button>
                        }
                        <p className="balance-time pt-2">Start Time : {time?.StartTime} </p>
                        {
                            time?.EndTime ?
                                <p className="balance-time pt-2">End Time : {time?.EndTime} </p>
                                : null
                        }
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Col lg={12} id="chat-feed" ref={chatfeedRef} className="d-flex flex-column chat-messages">
                        <Col lg={12} className="d-flex flex-column align-items-start chat-connect mb-3">
                            <div className="cus-details">
                                <h5>Customer Details</h5>
                                <h5><span className="f-w-500"> Name :</span> {waitingList?.waiting?.subuser?.name}</h5>
                                <h5><span className="f-w-500"> DOB :</span> {waitingList?.waiting?.subuser?.dateofbirth}</h5>
                                {
                                    waitingList?.waiting?.subuser?.gender ?
                                        <h5><span className="f-w-500"> Gender :</span> {waitingList?.waiting?.subuser?.gender}</h5>
                                        : null
                                }
                                <h5><span className="f-w-500"> Birth Place :</span> {waitingList?.waiting?.subuser?.placeofbirth}</h5>
                                <h5><span className="f-w-500"> Birth Time :</span> {waitingList?.waiting?.subuser?.timeofbirth}</h5>
                            </div>
                        </Col>

                        <Col lg={12} className={`d-flex justify-content-start`}>
                            <div className="chat-message">
                                <p>Please wait a moment as our customer joins the chat.</p>
                            </div>
                        </Col>
                        <Col lg={12} className={`d-flex justify-content-center chat-connect`}>
                            {
                                InprogressChatStatus?.data?.data?.status === 'in-progress' ?
                                    <p>Your Chat has been connected</p>
                                    : InprogressChatStatus?.data?.data?.status === 'pending' ?
                                        null
                                        : null

                            }
                        </Col>


                        {
                            receivedMessage.map((item, index) => {
                                const url = item.message;
                                const imageExtension = url?.split('.').pop().toLowerCase();
                                const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'].includes(imageExtension);
                                const isFile = ['pdf', 'csv', 'xlsx', 'txt', 'pptx', 'docx', 'doc', 'zip'].includes(imageExtension);

                                const urlFile = isFile ? new URL(url) : null;
                                const pathname = isFile ? urlFile.pathname : null;

                                return (
                                    <>
                                        <Col lg={12}
                                            className={`d-flex ${item.role === 'astrologer' ? 'justify-content-end' : 'justify-content-start'}`}
                                            key={index}
                                        >

                                            {isImage ?
                                                <div className="chat-image" >
                                                    <img src={item.message} alt="chat-image" className="img-fluid" />
                                                    <div className="image-download">
                                                        <p><a href={item.message}>Download <MdDownload className="icon" /></a></p>
                                                        <span>{item.time}</span>
                                                    </div>
                                                </div>
                                                : isFile ?
                                                    <div className="chat-file">
                                                        <div className="file">
                                                            <a href={item.message}>
                                                                <FaFileDownload className="icon" />
                                                            </a>
                                                        </div>
                                                        <div className="file-name">
                                                            <p>{pathname?.slice(15)}</p>
                                                            <span>{item.time}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="chat-message">
                                                        <p>{item.message}</p>
                                                        <span>{item.time}</span>
                                                    </div>
                                            }

                                        </Col>
                                    </>
                                );
                            })
                        }

                        {/* {
                            leftMessage?.isleft ?
                                <Col lg={12}
                                    className="d-flex justify-content-center chat-connect"
                                >
                                    <p>{leftMessage?.role == 'user' ? "User" : null} left the chat</p>
                                </Col>
                                : null
                        }
                        {
                            chatEnd && !(leftMessage?.isleft) ?
                                <Col lg={12} className="d-flex justify-content-center chat-connect">
                                    <p>You left the chat</p>
                                </Col>
                                : null
                        } */}

                        {
                            InprogressChatStatus?.data?.data?.isEndedBy === 'User' ?
                                <Col lg={12} className="d-flex justify-content-center chat-connect">
                                    <p>User left the chat</p>
                                </Col>
                                : InprogressChatStatus?.data?.data?.isEndedBy === 'Astrologer' ?
                                    <Col lg={12} className="d-flex justify-content-center chat-connect">
                                        <p>You left the chat</p>
                                    </Col>
                                    : InprogressChatStatus?.data?.data?.isEndedBy === 'Automatically' ?
                                        <Col lg={12} className="d-flex justify-content-center chat-connect">
                                            <p>Chat order is completed</p>
                                        </Col>
                                        : null
                        }

                    </Col>
                    {chatEnd ?
                        null
                        :
                        <>
                            {
                                InprogressChatStatus?.data?.data?.status === 'in-progress' ?
                                    <Col lg={12} className="type-section py-3">
                                        <div className="text-input">
                                            <textarea
                                                name=""
                                                id=""
                                                placeholder="Type Messages Here........."
                                                ref={textareaRef}
                                                value={message}
                                                onChange={(e) => {
                                                    handleChangeMessage(e);
                                                }}
                                                onKeyDown={handleKeyPress}
                                                // onFocus={handleTyping}
                                                // onBlur={handleNotTyping}
                                                required
                                            ></textarea>
                                            <button>
                                                <TiAttachment
                                                    className="icon"
                                                    onClick={() => setFileStatus(true)}
                                                />
                                            </button>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <button>
                                                <IoSend
                                                    className="icon"
                                                    onClick={(e) => sendMessage(e)}
                                                />
                                            </button>
                                        </div>
                                        {window.screen.width < 950 ?
                                            <div className="d-flex align-items-center">
                                                <button
                                                    className="chart-img"
                                                    onClick={() => setViewChart(true)}
                                                >
                                                    <img src={Chart} className="img-fluid" />
                                                </button>
                                            </div>
                                            : null
                                        }
                                    </Col>
                                    : null
                            }
                        </>


                    }
                </Offcanvas.Body>
            </Offcanvas >
        </>
    )
}

export default ChatConnect;
